"use client";

import { cn } from "@/lib/utils";
import React, { useRef, useEffect, ReactNode, useCallback } from "react";

// Define possible positions for the dropdown menu
type Position = "bottom-left" | "bottom-right" | "top-left" | "top-right" | "bottom-center";

interface DropdownMenuProps {
  trigger: ReactNode;
  children: (closeMenu: () => void) => ReactNode;
  isOpen: boolean;
  onToggle: () => void;
  position?: Position;
  containerClassName?: string;
  className?: string;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  trigger,
  children,
  isOpen,
  onToggle,
  position = "bottom-center", 
  className,
  containerClassName
}) => {
  const menuRef = useRef<HTMLDivElement>(null); 

   // Function to close the menu
   const closeMenu = useCallback(() => {
    if (isOpen) onToggle(); 
  }, [isOpen, onToggle]);


  // Effect to handle clicks outside the menu to close it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        closeMenu();
      }
    };

    // Add event listener for mouse clicks
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeMenu]);

  // Classes for positioning the dropdown menu based on the position prop
  const positionClasses = {
    "bottom-left": "left-0 mt-2",
    "bottom-right": "right-0 mt-2",
    "top-left": "left-0 bottom-full mb-2",
    "top-right": "right-0 bottom-full mb-2",
    "bottom-center": "left-1/2 transform -translate-x-1/2 mt-2",
  };

  return (
    <div className={cn("relative inline-block", containerClassName)} ref={menuRef}>
      {/* Trigger element for opening the dropdown */}
      <div onClick={onToggle} className="cursor-pointer">
        {trigger}
      </div>
      {/* Render the dropdown menu if it's open */}
      {isOpen && (
        <div
          className={cn(`absolute ${positionClasses[position] || positionClasses["bottom-center"]} min-w-36 rounded-md shadow-shadows1 bg-white ring-1 ring-black ring-opacity-5 z-50 p-3 ${className}`)}
        >
          {children(closeMenu)} {/* Render the content of the dropdown and pass closeMenu function */}
        </div>
      )}
    </div>
  );
};

export { DropdownMenu };

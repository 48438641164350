import { BranchesState } from '@/types/next';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { VisitorInfoState } from '../visitorInfo/visitorInfoSlice';
import { toast } from 'react-toastify';

/**
 * @function parseSavedData
 * Parses saved branch information from localStorage.
 * 
 * @returns {SavedBranchInfo | null} Parsed data if successful, or null in case of an error or if no data is found.
 */
function parseSavedData() {
  try {
    if (typeof window === 'undefined') return null;  // Ensure this runs in the browser environment
    const data = localStorage.getItem('bwf');  // Get the saved branch data from localStorage
    if (data === undefined || data === null) return null;  // Return null if data is undefined or null
    return JSON.parse(data);  // Parse the data into JSON and return it
  } catch (error) {
    console.error('Error parsing saved data:', error);  // Log any errors encountered during parsing
    return null;  // Return null if parsing fails
  }
}

/**
 * Initial state for the branchesSlice
 * @const
 * @type {BranchesState}
 */
const initialState: BranchesState = {
  data: [],  /** Holds the list of branches fetched from the server */
  loadingNearbyBranches: false,  /** Tracks whether branches are being fetched */
  loadingSlots: false,  /** Tracks whether slots are being fetched */
  errorNearbyBranches: null,  /** Holds any error that occurs while fetching branches */
  errorSlots: null,  /** Holds any error that occurs while fetching slots */
  slotsData: null,  /** Holds the slot information if available */
  collectedBookingDetails: null,  /** Holds collected booking details */
  tableBookingEstimate: {
    subtotal: 0,  /** Stores the subtotal for table booking */
    cgst: 0,      /** Stores the CGST amount */
    sgst: 0,      /** Stores the SGST amount */
    serviceCharge: 0,  /** Stores the service charge */
    total: 0,  /** Stores the total amount */
    itemTotal: 0,  /** Stores the total of all items */
  },
  saved: parseSavedData(),  /** Load any saved branch data from localStorage during initialization */

  old_lat: null, // Track the last used latitude
  old_long: null, // Track the last used longitude
  lastFetched: null, // Initialize as null 

};

/**
 * Async thunk for fetching nearby branches.
 * Sends a POST request to the server with latitude and longitude, and fetches nearby branches.
 * 
 * @param {{ lat: string, long: string }} param0 - Contains latitude and longitude for fetching nearby branches.
 * @returns {Promise<any[]>} - Returns a promise that resolves with the fetched branch data or an error.
 */
export const fetchNearbyBranches = createAsyncThunk(
  'branches/fetchNearbyBranches',
  async ({ lat, long }: { lat: string; long: string }, { getState, dispatch, rejectWithValue }) => {

    try {

      // debugger
      const state = getState() as { visitorInfo: VisitorInfoState; branches: BranchesState };
      const { latitude, longitude } = state.visitorInfo;
      const { data, old_lat, old_long, lastFetched } = state.branches;
      // If visitorInfo latitude and longitude are null, fetch from server


      const FOUR_HOURS_IN_MS = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
      const now = Date.now();
      if (lastFetched && now - lastFetched < FOUR_HOURS_IN_MS) {
           
      }else{
        const result = await fetchFromServer(lat, long, dispatch); // Helper function
        dispatch(updateOldLocation({
          old_lat: parseFloat(lat),
          old_long: parseFloat(long),
          lastFetched: Date.now(),
        }));
        return result;
      }

        // time logic here for 4 hrs start
        if ((latitude === null && longitude === null) && (Object.entries(data).length == 0 && data.length == 0)) {
          const result = await fetchFromServer(lat, long, dispatch); // Helper function
          return result;
        } else if (
          parseFloat(lat) === old_lat &&
          parseFloat(long) === old_long &&
          data
        ) {
          return data;
        }
        else if (
          parseFloat(lat) !== old_lat &&
          parseFloat(long) !== old_long
        ) {
          const result = await fetchFromServer(lat, long, dispatch); // Helper function
          // Update old_lat, old_long, and lastFetched in branchesSlice
          dispatch(updateOldLocation({
            old_lat: parseFloat(lat),
            old_long: parseFloat(long),
            lastFetched: Date.now(),
          }));
          return result;
        }
        else if (Object.entries(data).length > 0) {
          return data;
        }
        // time logic here for 4 hrs end 



      // Location has changed, fetch new data from server
      console.log('Location has changed. Fetching new data from server.');
      const result = await fetchFromServer(lat, long, dispatch);
      return result;
    } catch (error: any) {
      console.error('Error fetching nearby branches:', error);
      return rejectWithValue(error.message);
    }
  }

);

// Helper function to fetch data from server
async function fetchFromServer(lat: string, long: string, dispatch: any) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({ lat, long });
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow' as RequestRedirect,
    cache: 'force-cache' as RequestCache,
  };

  const response = await fetch('/api/v1/get-all-nearby-branches', requestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    if(errorData?.error.includes('Blocked by Cloudflare'))
      toast.warn(`${errorData?.error}`)
    throw new Error(errorData);
  }

  const result = await response.json();

  // Update VisitorInfo latitude and longitude
  // dispatch(setGeoLocation({ latitude: parseFloat(lat), longitude: parseFloat(long) }));

  // Update old_lat and old_long in branchesSlice
  if (lat != null && long != null)
    dispatch(updateOldLocation({ old_lat: parseFloat(lat), old_long: parseFloat(long), lastFetched: Date.now() }));

  // Save new data to cache
  const newBranchData = result?.results || [];
  return newBranchData;
}


// ========================================================================================================== //
// ========================================================================================================== //
// branchesSlice: Reducer and state management for branches
// ========================================================================================================== //
// ========================================================================================================== //

/**
 * branchesSlice: Manages the state of branches including fetching, saving, and retrieving branches.
 */
const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    /**
     * @function saveBranch
     * Saves the branch data to localStorage and updates the saved branch in the state.
     * 
     * @param {BranchesState} state - Current state of branches
     * @param {PayloadAction<string>} action - The payload containing the branch data in string form
     */
    saveBranch: (state, action: PayloadAction<string>) => {
      try {
        const branch = action.payload;
        localStorage.setItem('bwf', branch);  // Save the branch data to localStorage
        state.saved = JSON.parse(branch);  // Update the saved branch in the state
      } catch (error) {
        state.saved = null;  // Reset the saved state if an error occurs
      }
    },

    /**
     * @function getSavedBranch
     * Retrieves the saved branch data from localStorage and updates the state.
     * 
     * @param {BranchesState} state - Current state of branches
     */
    getSavedBranch: (state) => {
      try {
        state.saved = parseSavedData();  // Load saved branch data from localStorage
      } catch (error) {
        state.saved = null;  // Reset the saved state if an error occurs
      }
    },


    updateOldLocation: (state, action: PayloadAction<{ old_lat: number; old_long: number, lastFetched: number }>) => {
      state.old_lat = action.payload.old_lat;
      state.old_long = action.payload.old_long;
      state.lastFetched = action.payload.lastFetched;
    },

  },

  extraReducers: (builder) => {
    builder
      /**
       * Handles the pending state when fetching nearby branches.
       * @param {BranchesState} state - Current state of branches
       */
      .addCase(fetchNearbyBranches.pending, (state) => {
        state.loadingNearbyBranches = true;  // Set loading to true while fetching
        state.errorNearbyBranches = null;  // Reset any previous errors
      })

      /**
       * Handles the successful response when fetching nearby branches.
       * @param {BranchesState} state - Current state of branches
       * @param {PayloadAction<any[]>} action - The payload containing the fetched branches
       */
      .addCase(fetchNearbyBranches.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.loadingNearbyBranches = false;  // Loading is complete
        state.data = action.payload;  // Update the branch data in the state
      })

      /**
       * Handles errors when fetching nearby branches fails.
       * @param {BranchesState} state - Current state of branches
       * @param {PayloadAction<any>} action - The payload containing the error message
       */
      .addCase(fetchNearbyBranches.rejected, (state, action: PayloadAction<any>) => {
        state.loadingNearbyBranches = false;  // Stop loading
        state.errorNearbyBranches = action.payload;  // Update the error message
      });
  },
});

// ========================================================================================================== //
// ========================================================================================================== //
// slotsSlice: (Optional) Logic for managing slots can go here.
// ========================================================================================================== //
// ========================================================================================================== //

export const { saveBranch, getSavedBranch, updateOldLocation } = branchesSlice.actions;
export default branchesSlice.reducer;

"use client";
import React, { Fragment, useState, useEffect } from 'react';
import Icon from './Icon';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNearbyBranches, saveBranch } from '@/store/slices/table_booking_flow/branchesSlice';
import { AppDispatch, AppState } from '@/store/store';
import useGeolocation from '@/hooks/useGeolocation';
import { findMatchingBranch } from '@/lib/utils';
import { useRouter } from 'next/navigation';


interface Branch {
    branch_id: string;
    branch_name: string;
    latitude: string;
    longitude: string;
    brand_id: string;
    branch_logo: string;
}

interface City {
    city_name: string;
    city_code: string;
    weight: number;
    branches: Branch[];
}

export type LocationDropdownProps = {
    options?: City[],
    onOptionClick?: (city: string, place: string, other?: any) => void;
}

/**
 * Location component allows users to select a location either by searching for a city/branch or using GPS to find nearby branches.
 * @param {LocationDropdownProps} props - The props for the Location component.
 * @returns {React.FC} The rendered Location dropdown component.
 */
const Location: React.FC<LocationDropdownProps> = ({ options = [], onOptionClick }) => {
    const router = useRouter()

    const dispatch = useDispatch<AppDispatch>();
    const [searchInput, setSearchInput] = useState<string>('');
    const [filteredOptions, setFilteredOptions] = useState<City[]>(options);
    const { location, requestLocation } = useGeolocation();
    const [lock, setUnlock] = useState(false);
    const { data: branches, loadingNearbyBranches: loading, errorNearbyBranches: error } = useSelector((state: AppState) => state.branches);

    /**
     * Filters the list of branches based on the search keyword.
     * @param {City[]} cities - Array of cities containing branches.
     * @param {string} keyword - The search input for filtering.
     * @returns {City[]} Filtered array of cities containing branches that match the keyword.
     */
    function filterBranchesByKeyword(cities: City[], keyword: string): City[] {
        const lowerCaseKeyword = keyword.toLowerCase();

        return cities
            .map((city) => {
                // Check if city name matches the keyword
                const cityMatches = city.city_name.toLowerCase().includes(lowerCaseKeyword);

                // Filter branches that match the keyword
                const filteredBranches = city.branches.filter((branch) =>
                    branch.branch_name.toLowerCase().includes(lowerCaseKeyword)
                );

                // If the city name matches or there are matching branches, return the city with the filtered branches
                if (cityMatches || filteredBranches.length > 0) {
                    return {
                        ...city,
                        branches: cityMatches ? city.branches : filteredBranches, // If city matches, include all its branches
                    };
                }
                // Otherwise, return null (to filter out non-matching cities)
                return null;
            })
            .filter((city) => city !== null) as City[]; // Filter out null values
    }

    useEffect(() => {
        // Update the filtered options based on the search input
        setFilteredOptions(filterBranchesByKeyword(options, searchInput));
    }, [searchInput, options]);

    useEffect(() => {
        if (lock && branches && 'near_by' in branches && (branches.near_by).length > 0) {
            const matchingBranch = findMatchingBranch(branches?.data, branches?.near_by[0]);
            if (matchingBranch) {
                dispatch(saveBranch(JSON.stringify(matchingBranch)));
            }
            setUnlock(false);
        }
    }, [branches?.near_by, lock]);

    return (
        <div>
            <button
                className="flex items-center justify-start mb-2 cursor-pointer border shadow-shadows1 border-gray-2 px-2 py-1 rounded-md hover:bg-primary-1 duration-300 w-full"
                onClick={() => {
                    requestLocation().then((vale: any) => {
                        dispatch(fetchNearbyBranches({ lat: vale?.latitude.toString(), long: vale?.longitude.toString() }));
                    });
                    setUnlock(true);
                }}
            >
                <Icon name="gps" size={20} className="mr-2" />
                <div className="text-left">
                    <span className='font-inter text-xs text-primary-5'> Near By Location</span>
                    <span className="block text-gray-4 text-[10px]">Using GPS</span>
                </div>
            </button>
            <div className="relative">
                <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="w-full border-[#EEEFF1] border rounded-[4px] outline-none px-[10px] pr-[30px] py-[6px] font-inter"
                    placeholder="Search..."
                />
                <Icon name="search" color='#292929' size={20} className="w-[14px] absolute top-[50%] right-3 translate-y-[-50%]" />
            </div>
            <ul className="mt-4 max-h-[310px] h-full overflow-y-auto relative">
                {filteredOptions?.map((option, index) => (
                    <Fragment key={index}>
                        <li>
                            <h4 className="font-inter leading-[14.4px] font-bold text-3 text-gray-5 mb-2">
                                {option.city_name}
                            </h4>
                            <ul>
                                {option?.branches?.map((place : any, ind) => (
                                    <li
                                        key={ind}
                                        onClick={() => {
                                            dispatch(saveBranch(JSON.stringify({
                                                branch_name: place.branch_name,
                                                city_name: option.city_name,
                                                details: place
                                            })));

                                            let path = window?.location.pathname
                                            if(path.includes('/restaurants')){
                                                router.push(place?.branch_alias)
                                            }
                                           
                                            onOptionClick?.(option.city_name || '', place.branch_name, place);
                                        }}
                                        className="font-inter whitespace-nowrap overflow-hidden leading-[22.4px] font-normal text-[14px] rounded-[4px] cursor-pointer duration-100 text-gray-5 mb-2 py-2 px-[10px] hover:bg-primary-1"
                                    >
                                        {place.branch_name}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </Fragment>
                ))}
                {
                    !filteredOptions || filteredOptions.length === 0 && <span className='text-sm font-inter'>No Result Found</span>
                }
            </ul>
        </div>
    );
};

export default Location;
